<template>
  <div
      class="index-hero relative flex justify-center h-screen min-h-[800px] overflow-hidden index-hero--background"
  >
    <UiCustomImage
      :src="imageDesktop"
      alt=""
      presets="index_hero_desktop"
      customClass="absolute w-screen min-h-[800px] vlh:h-screen vlh:min-h-[auto] object-cover hidden md:block"
    />
    <UiCustomImage
      :src="imageMobile"
      alt=""
      presets="index_hero_mobile"
      customClass="absolute block w-screen h-screen object-cover block md:hidden"
    />
    <div class="container relative">
      <div class="bottom-[230px] vlh:bottom-[200px] absolute left-4 right-4 lh:bottom-[100px] xl:max-w-[70%] 3xl:max-w-[80%]">
        <div
            class="text-h3 xl:text-h0 font-normal text-white font-lora leading-[45px] xl:leading-[70px] index-hero--text"
            v-html="description"/>
        <div
            class="text-medium xl:text-h2Small pt-8 font-normal text-white font-lora leading-[24px] md:leading-[40px] index-hero--text"
            v-html="description2" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndexHero",
  props: {
    imageDesktop: {
      type: String,
      required: true,
    },
    imageMobile: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    description2: {
      type: String,
      required: true,
    },
  }
};
</script>
<style>
.theme-dark {
  .index-hero {
    @apply bg-black;

    img {
      @apply opacity-60;
    }
  }
}
</style>
