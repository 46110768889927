import { gql } from "graphql-tag";

export const HomeQuery = gql`
  query MyQuery($id: ID!, $langcode: Langcode!) {
    entityById(entityType: NODE, id: $id, langcode: $langcode) {
      ... on NodeHome {
        id
        langcode
        translations {
          pathRawField {
            list {
              alias
              langcode
            }
          }
        }
        fieldMetatag {
          value
        }
        fieldImage {
          alt
          entity {
            uriRawField {
              list {
                url
              }
            }
          }
        }
        fieldImageMobile {
          alt
          entity {
            uriRawField {
              list {
                url
              }
            }
          }
        }
        fieldDescription
        fieldDescription2
        fieldImageCancer {
          alt
          entity {
            uriRawField {
              list {
                url
              }
            }
          }
        }
        fieldTitleCancer
        fieldTextCancer
        fieldCancerLink {
          title
          uri {
            path
          }
        }
        fieldCancerLinkBottom {
          title
          uri {
            path
          }
        }
        fieldTitleEditorial
        fieldHomeEditorial {
          fieldLink {
            title
            uri {
              path
            }
          }
          fieldImage {
            alt
            entity {
              uriRawField {
                list {
                  url
                }
              }
            }
          }
        }
        fieldTitleAction
        fieldHomeAction {
          fieldImages {
            alt
            entity {
              uriRawField {
                list {
                  url
                }
              }
            }
          }
          fieldTitle
          fieldLink {
            title
            uri {
              path
            }
          }
          fieldTextColor
          fieldBackgroundColor
        }
        fieldHomeProject {
          fieldImage {
            alt
            entity {
              uriRawField {
                list {
                  url
                }
              }
            }
          }
          fieldIntroduction
          fieldTitle
          fieldBody
          fieldLink {
            title
            uri {
              path
            }
          }
        }
        fieldTitleNews
        fieldTitleLegacy
        fieldIntroductionLegacy
        fieldTextLegacy
        fieldLinkLegacy {
          title
          uri {
            path
          }
        }
        fieldImageLegacy {
          alt
          entity {
            uriRawField {
              list {
                url
              }
            }
          }
        }
        fieldImageCooperation {
          alt
          entity {
            uriRawField {
              list {
                url
              }
            }
          }
        }
        fieldSubtitle
        fieldTitleCooperation
        fieldTextCooperation
        fieldLogoCooperation {
          alt
          entity {
            uriRawField {
              list {
                url
              }
            }
          }
        }
        fieldLink {
          title
          uri {
            path
          }
        }
        computedNewsHighlighted {
          ... on NodeNews {
            id
            pathRawField {
              list {
                alias
              }
            }
            fieldSubtitle
            fieldTitle
            fieldDate {
              value
            }
            fieldImage {
              alt
              entity {
                uriRawField {
                  list {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
