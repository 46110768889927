import { HomeQuery } from "../../query/content/Home";
import type { HomeItems } from "~/interfaces/home";



export default async function getHome(id: string, langcode: string) {
  const TAGS : string[] = [];
  const key = `page--${id}`;
  const { data: home } = await useAsyncData(key, async () => {
    const { value, addToCache } = await useDataCache(key)
    if (value) {
      return value
    }
    const { data: response } =  await useAsyncQuery<HomeItems>(HomeQuery, { id, langcode });
    TAGS.push(`page:${id}`);
    if(response._value.entityById?.computedNewsHighlighted){
      for (const element of response._value.entityById?.computedNewsHighlighted) {
        TAGS.push(`page:${element.id}`);
      }
    }
    await addToCache(response._value, TAGS);
    return response._value;
  })
  return {data: home._value };
}